import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getCompanyList, gatherInformation, deleteCompany } from "./HomeService.js";

const Home = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [tags, setTags] = useState([]);
    const [isTagModalOpen, setIsTagModalOpen] = useState(false);
    const [newTag, setNewTag] = useState('');
    const [sortOption, setSortOption] = useState('date-sourced-reverse');
    const [link, setLink] = useState('');
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        fetchCompanies();
        const interval = setInterval(() => {
            setCompanies(prev => [...prev]);
        }, 1000); // Update progress bars every second
        return () => clearInterval(interval); // Clear interval on unmount
    }, []);

    const fetchCompanies = async () => {
        try {
            const newCompanyList = await getCompanyList();
            setCompanies(newCompanyList || []);
        } catch (error) {
            console.error('Error fetching company list:', error);
            setCompanies([]);
        }
    };

    const handleCompanyClick = (baseUrl) => {
        const companyUrl = baseUrl.replace(/^(https?:\/\/)?/, '').replace(/\/$/, '');
        window.location.href = `/home/${companyUrl}`;
    };

    const handleAddTag = () => {
        alert('Filter by tags coming soon');
    };

    const handleRemoveTag = (tag) => {
        setTags(tags.filter(t => t !== tag));
        setSelectedTags(selectedTags.filter(t => t !== tag));
    };

    const handleScrape = async () => {
        let formattedLink = link;

        alert('Gathering information is in progress. New searches may take a moment to appear.');

        if (link.startsWith('www.')) {
            formattedLink = `http://${link}`;
        } else if (!link.startsWith('https://') && !link.startsWith('http://')) {
            alert('Please enter a valid URL starting with http://, https://, or www.');
            return;
        }

        try {
            await gatherInformation(formattedLink);
            window.location.reload(); // Reload page after gathering information
        } catch (error) {
            console.error('Error gathering company information:', error);
        }
    };

    const handleDeleteClick = async (company) => {
        if (window.confirm(`Are you sure you want to delete ${company.company_name}?`)) {
            try {
                await deleteCompany(company.base_url);
                fetchCompanies(); // Refresh the company list after deletion
            } catch (error) {
                console.error(`Error deleting ${company.company_name}:`, error);
            }
        }
    };

    const filteredCompanies = companies.filter(company => {
        const matchesTags = selectedTags.length === 0 || selectedTags.every(tag =>
            company.tags.some(companyTag => companyTag.toLowerCase() === tag.toLowerCase())
        );
        const matchesDate = (!startDate || new Date(company.date_sourced * 1000) >= startDate) &&
                            (!endDate || new Date(company.date_sourced * 1000) <= endDate);
        const matchesSearchQuery = searchQuery === '' ||
            company.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            company.base_url.toLowerCase().includes(searchQuery.toLowerCase());

        return matchesTags && matchesDate && matchesSearchQuery;
    });

    const sortedCompanies = [...filteredCompanies].sort((a, b) => {
        if (sortOption === 'alphabetical') {
            return a.company_name?.localeCompare(b.company_name) || 0;
        } else if (sortOption === 'reverse-alphabetical') {
            return b.company_name?.localeCompare(a.company_name) || 0;
        } else if (sortOption === 'date-sourced') {
            return new Date(a.date_sourced * 1000) - new Date(b.date_sourced * 1000);
        } else if (sortOption === 'date-sourced-reverse') {
            return new Date(b.date_sourced * 1000) - new Date(a.date_sourced * 1000);
        }
        return 0;
    });

    return (
        <div className="bg-[#0F1A2D] min-h-screen flex flex-col text-white">
            {/* Header */}
            <header className="bg-[#0A1128] py-16 text-center">
                <h1 className="text-white font-bold text-6xl">You Focus on Strategy</h1>
                <h2 className="text-white font-bold text-5xl mt-4">We'll handle the Data</h2>
                <p className="text-[#D1D5DB] font-bold text-xl mt-6 max-w-2xl mx-auto">
                    Automate data gathering and spend more time on high-impact analysis
                </p>

                <div className="mt-[5em] mb-[3em] flex flex-col lg:flex-row justify-center items-center space-x-0 lg:space-x-4 space-y-4 lg:space-y-0">
                    <input
                        type="text"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        className="w-full lg:w-2/5 p-3 bg-[#1B263B] text-[#D1D5DB] rounded-md"
                        placeholder="Enter link (e.g. https://www.desync.ai)"
                    />
                    <button
                        onClick={handleScrape}
                        className="bg-[#684949] text-[#E5E7EB] px-4 py-3 rounded-md"
                    >
                        Gather Insights
                    </button>
                </div>
            </header>

            {/* Sort and Filter section */}
            <div className="flex flex-col lg:flex-row justify-between px-8 py-6 lg:py-12 space-y-6 lg:space-y-0 lg:space-x-6">
                {/* Sort Section */}
                <div className="w-full lg:w-1/4">
                    <h3 className="text-white font-bold text-4xl mb-4">Sort</h3>
                    <div className="bg-[#1C2A3E] p-4 rounded-lg h-48">
                        <div className="flex flex-col space-y-2">
                            <label className="text-white flex items-center space-x-2">
                                <input
                                    type="radio"
                                    name="sortOption"
                                    value="date-sourced-reverse"
                                    checked={sortOption === 'date-sourced-reverse'}
                                    onChange={(e) => setSortOption(e.target.value)}
                                />
                                <span>Date Sourced (Newest to Oldest)</span>
                            </label>
                            <label className="text-white flex items-center space-x-2">
                                <input
                                    type="radio"
                                    name="sortOption"
                                    value="date-sourced"
                                    checked={sortOption === 'date-sourced'}
                                    onChange={(e) => setSortOption(e.target.value)}
                                />
                                <span>Date Sourced (Oldest to Newest)</span>
                            </label>
                            <label className="text-white flex items-center space-x-2">
                                <input
                                    type="radio"
                                    name="sortOption"
                                    value="alphabetical"
                                    checked={sortOption === 'alphabetical'}
                                    onChange={(e) => setSortOption(e.target.value)}
                                />
                                <span>Alphabetical</span>
                            </label>
                            <label className="text-white flex items-center space-x-2">
                                <input
                                    type="radio"
                                    name="sortOption"
                                    value="reverse-alphabetical"
                                    checked={sortOption === 'reverse-alphabetical'}
                                    onChange={(e) => setSortOption(e.target.value)}
                                />
                                <span>Reverse Alphabetical</span>
                            </label>
                        </div>
                    </div>
                </div>

                {/* Vertical Line */}
                <div className="hidden lg:block border-l-2 border-gray-500"></div>

                {/* Filter Section */}
                <div className="w-full lg:w-3/4">
                    <h3 className="text-white font-bold text-4xl mb-4">Filter</h3>
                    <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
                        <div className="w-full lg:w-1/3 bg-[#1C2A3E] p-4 rounded-lg h-48">
                            <h4 className="text-white font-bold mb-2">Company Name</h4>
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="w-full p-2 bg-[#374151] text-white rounded-md"
                                placeholder="Enter company name"
                            />
                        </div>

                        <div className="w-full lg:w-1/3 bg-[#1C2A3E] p-4 rounded-lg h-48">
                            <h4 className="text-white font-bold mb-2">Date Range</h4>
                            <div className="space-y-4">
                                <div className="flex items-center">
                                    <span className="text-white mr-2">From:</span>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        className="w-[158.17px] h-[24px] p-2 bg-[#374151] text-white rounded-md"
                                        placeholderText="Start date"
                                    />
                                </div>
                                <div className="flex items-center">
                                    <span className="text-white mr-5">To:</span>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        className="w-[158.17px] h-[24px] ml-2 p-2 bg-[#374151] text-white rounded-md"
                                        placeholderText="End date"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full lg:w-1/3 bg-[#1C2A3E] p-4 rounded-lg h-48">
                            <h4 className="text-white font-bold mb-2">Tags</h4>
                            <div className="flex flex-wrap gap-2">
                                {tags.map((tag, index) => (
                                    <div key={index} className="bg-gray-600 text-gray-200 px-2 py-1 rounded flex items-center">
                                        {tag}
                                        <span onClick={() => handleRemoveTag(tag)} className="ml-2 text-red-400 cursor-pointer">&times;</span>
                                    </div>
                                ))}
                                <button onClick={handleAddTag} className="bg-gray-600 text-gray-200 px-2 py-1 rounded">Add Tag</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="border-gray-700 mb-4 lg:mb-20 mx-4 lg:mx-10" style={{ borderWidth: '2px' }} />

            {/* Table Section */}
            <div className="px-4 lg:px-10 mb-20">
                <table className="min-w-full bg-[#243447] text-white rounded-lg overflow-hidden">
                    <thead>
                        <tr className="bg-[#0A1128]">
                            <th className="py-2 px-4 text-left font-bold">Name</th>
                            <th className="py-2 px-4 text-left font-bold hidden sm:table-cell">Date</th>
                            <th className="py-2 px-4 text-left font-bold hidden sm:table-cell">Website</th>
                            <th className="py-2 px-4 text-center font-bold">Completed</th>
                            <th className="py-2 px-4 text-center font-bold">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedCompanies.map((company, index) => (
                            <tr
                                key={index}
                                className="hover:bg-gray-700 cursor-pointer"
                                onClick={() => handleCompanyClick(company.base_url)}
                            >
                                <td className="py-2 px-4">{company.company_name}</td>
                                <td className="py-2 px-4 hidden sm:table-cell">{new Date(company.date_sourced * 1000).toLocaleDateString()}</td>
                                <td className="py-2 px-4 hidden sm:table-cell">{company.base_url}</td>
                                <td className="py-2 px-4 text-center">
                                    {Date.now() / 1000 - company.date_sourced > 45 ? '✓' : (
                                        <div className="w-full bg-gray-400 rounded-full h-4">
                                            <div
                                                className="bg-blue-600 h-4 rounded-full"
                                                style={{ width: `${Math.min(((Date.now() / 1000 - company.date_sourced) / 45) * 100, 100)}%` }}
                                            ></div>
                                        </div>
                                    )}
                                </td>
                                <td className="py-2 px-4 text-center">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteClick(company);
                                        }}
                                        className="bg-transparent hover:bg-red-600 text-white rounded-full p-1"
                                    >
                                        🗑️
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Home;
