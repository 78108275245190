import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-[#1B263B] p-4 shadow-md">
            <div className="container mx-auto text-center">
                <p className="text-white">Copyright 2024 all rights reserved</p>
            </div>
        </footer>
    );
};

export default Footer;
